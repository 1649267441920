import loadable from '@loadable/component';

const Blocks = {
    call_to_action: loadable(() => import('./CallToAction')),
    text_section: loadable(() => import('./TextSection')),
    newsletter: loadable(() => import('./Newsletter')),
    panel: loadable(() => import('./Panel')),
    discover: loadable(() => import('./Discover')),
    products_promotion: loadable(() => import('./ProductsPromotion')),
    information: loadable(() => import('./Information')),
    articles_promotion: loadable(() => import('./ArticlesPromotion')),
    product_promotion: loadable(() => import('./ProductPromotion')),
    article_hero: loadable(() => import('./ArticleHero')),
    html: loadable(() => import('./Html')),
    faq: loadable(() => import('./Faq')),
};

export default Blocks;
