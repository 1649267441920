import { above } from '@sportson/core-web/utils/mediaqueries';
import variables from 'config/variables';
import styled from 'libs/styled';

/**
 * Adds a 14-grid wrapper that works like a 12-grid container
 *
 * column 1 and 14 works as a 'full-width' module enabler, while 'standard' spans over the standard 12 gaps.
 * 'narrow' is a custom value set to have a 1 column margin at both sides.
 * To use this wrapper it is important to set a gridColumn - value on the containers children ['full-width' | 'standard' | 'narrow']
 *
 * You can also put a custom gridColumn value (like gridColumn; '2 / span 10')
 *
 * Example:
 *  <ContentGridWrapper rowGap="32px">
 *    <FullWidthModule gridColumn="full-width"/>
 *    <StandardWidthModule gridColumn="standard"/>
 *    <ResponsiveWidthModule gridColumn=['full-width', null, null, 'narrow']/>
 *  </ContentGridWrapper>
 */

export const gridMargin = {
    mobile: '12px',
    desktop: '40px',
};

export const gridGap = {
    mobile: '12px',
    desktop: '24px',
};

export const contentPadding = [gridMargin.mobile, null, null, gridMargin.desktop];

const ContentGridWrapper = styled('div')`
    --full-max-width: ${variables.maxContentWidth};
    --column-gap-size: ${gridGap.mobile};
    --side-margins: ${gridMargin.mobile};
    --padding-inline: calc(var(--side-margins) - var(--column-gap-size));

    // Desired width of "12-column grid"
    --standard-max-width: calc(var(--full-max-width) - (var(--side-margins) * 2));

    // Calculates the maximum width each column is allowed to have within the "12-column grid" to keep the desired standard-max-width,
    // only first and last column of the 14-grid is expandable beyond these values.
    --max-fr-size: calc((var(--standard-max-width) / 12) - (calc(var(--column-gap-size) * 11) / 12));

    position: relative;
    min-height: 100vh;
    width: 100%;
    align-content: start;
    max-width: var(--full-max-width);
    margin-left: auto;
    margin-right: auto;

    display: grid;
    column-gap: var(--column-gap-size);

    grid-template-columns:
        [full-width-start]
        minmax(var(--padding-inline), 1fr)
        [standard-start]
        minmax(0, var(--max-fr-size))
        [narrow-start]
        repeat(10, minmax(0, var(--max-fr-size)))
        [narrow-end]
        minmax(0, var(--max-fr-size))
        [standard-end]
        minmax(var(--padding-inline), 1fr)
        [full-width-end];

    ${above['desktop.sm']} {
        --column-gap-size: ${gridGap.desktop};
        --side-margins: ${gridMargin.desktop};
    }
`;

export default ContentGridWrapper;
