import React from 'react';
import styled from '@grebban/style-system-react';
import PropTypes from 'prop-types';

const StyledWrapper = styled('div')`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
`;

const Waterfall = ({ children, rowGap = '0px', ...rest }) => (
    <StyledWrapper rowGap={rowGap} {...rest}>
        {children}
    </StyledWrapper>
);

Waterfall.propTypes = {
    children: PropTypes.node,
    rowGap: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default Waterfall;
