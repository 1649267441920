import responsiveBreakpointsStyle from '@sportson/core-web/utils/responsiveBreakpointsStyle';
import styled from 'libs/styled';
import { contentPadding } from 'components/wrappers/ContentGridWrapper';

/**
 * Used to negate the padding from ContentWrapper.
 * Useful if some content inside a ContentWrapper should cover the fullwidth of the viewport
 */

const BleedWrapper = styled('div', {
    shouldForwardProp: (prop) => ['customContentPadding'].indexOf(prop) === -1,
})`
    position: relative;
    ${({ customContentPadding }) =>
        responsiveBreakpointsStyle(
            customContentPadding || contentPadding,
            'margin-left: -{value}; margin-right: -{value};',
        )}
`;

export default BleedWrapper;
