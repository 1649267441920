import React from 'react';
import useBreakpoint from '@sportson/core-web/hooks/useBreakpoint';
import { above } from '@sportson/core-web/utils/mediaqueries';
import styled from '@grebban/style-system-react';

import CmsBlocks from '@sportson/core-web/libs/wordpress/content/CmsBlocks';
import Columns from '@sportson/core-web/App/layouts/Columns';
import FullWidth from '@sportson/core-web/App/layouts/FullWidth';
import BleedWrapper from 'components/wrappers/BleedWrapper';
import { contentPadding } from 'components/wrappers/ContentGridWrapper';
import Waterfall from '@sportson/core-web/App/layouts/Waterfall';

import { type WordpressCallToActionBlock } from 'libs/wordpress/content/Blocks/CallToAction';
import { type WordpressDiscoverBlock } from 'libs/wordpress/content/Blocks/Discover';
import { type WordpressInformationBlock } from 'libs/wordpress/content/Blocks/Information';
import {
    type WordpressFlexibleNavigation,
    type WordpressHeadingGroup,
} from 'libs/wordpress/types/contentSystem/groups';
import { transformLinkButton } from 'libs/wordpress/utils/transformButton';
import { transformHeadingGroup } from 'libs/wordpress/utils/transformHeading';
import { type WordpressContentRatio, type WordpressCustomColumnOrderThree } from '../../types/contentSystem/settings';
import { type WordpressSpacingGroup } from '../../types/contentSystem/groups';
// import { WordpressVideoInlineBlock } from "libs/wordpress/content/Blocks/VideoInline";
// import { WordpressBackgroundImageBlock } from "libs/wordpress/content/Blocks/Image";
import { type WordpressGenericFlexibleContent } from '../../types/acf/fields';

const ScrollWrapper = styled('div')`
    overflow: scroll;

    /* Remove native scroll */
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
        width: 0px;
    }

    .three_columns_item:last-of-type {
        padding-right: 12px;
    }

    ${above['mobile.lg']} {
        .three_columns_item:last-of-type {
            padding-right: 16px;
        }
    }

    ${above['tablet.md']} {
        .three_columns_item:last-of-type {
            padding-right: 0;
        }
    }
`;

interface WordpressThreeColumnModulesContentColumnsCallToAction extends WordpressGenericFlexibleContent {
    layout: 'callToAction';
    data: WordpressCallToActionBlock;
}
interface WordpressThreeColumnModulesContentColumnsDiscover extends WordpressGenericFlexibleContent {
    layout: 'discover';
    data: WordpressDiscoverBlock;
}

interface WordpressTwoColumnsModuleContentColumnsInformation extends WordpressGenericFlexibleContent {
    layout: 'information';
    data: WordpressInformationBlock;
}
// interface WordpressThreeColumnModulesContentColumnsImage extends WordpressGenericFlexibleContent {
//     layout: "image";
//     data: WordpressBackgroundImageBlock;
// }
// interface WordpressThreeColumnModulesContentColumnsVideoInline extends WordpressGenericFlexibleContent {
//     layout: "videoInline";
//     data: WordpressVideoInlineBlock;
// }

export type WordpressThreeColumnsModuleColumnContentBlocks =
    | WordpressThreeColumnModulesContentColumnsCallToAction
    | WordpressThreeColumnModulesContentColumnsDiscover
    | WordpressTwoColumnsModuleContentColumnsInformation;
// | WordpressThreeColumnModulesContentColumnsImage
// | WordpressThreeColumnModulesContentColumnsVideoInline;

export interface ThreeColumnsModuleProps extends Record<string, unknown> {
    data: {
        data: {
            columns: Array<
                WordpressThreeColumnsModuleColumnContentBlocks & {
                    customColumnOrder?: WordpressCustomColumnOrderThree;
                }
            >;
            heading?: WordpressHeadingGroup;
            link?: WordpressFlexibleNavigation;
            settings?: Array<'heading' | 'link'>;
        };
        moduleSettings: {
            reverseColumnsInMobile: 'no' | 'yes' | 'custom';
            spacing: WordpressSpacingGroup;
            contentRatio: WordpressContentRatio;
            contentLayout: {
                desktop: '33.333|33.333|33.333';
                mobile: 'slider' | 'rows';
                // These are not implemented in the CMS yet.
                // mobileRowLayoutSettings: {};
                // mobileSliderLayoutSettings: {};
            };
        };
    };
    isHero?: boolean;
}

export interface VolatileContentLayoutWrapper {
    children: React.ReactNode;
    isMobile: boolean;
    contentLayoutMobile: string;
    notDesktop: boolean;
}

const VolatileContentLayoutWrapper: React.FunctionComponent<VolatileContentLayoutWrapper> = ({
    children,
    isMobile,
    contentLayoutMobile,
    notDesktop,
}) => {
    const isSlider = contentLayoutMobile === 'slider' || contentLayoutMobile === 'default';
    if (isSlider && notDesktop) {
        return (
            <BleedWrapper>
                <ScrollWrapper direction="horizontal">
                    <Columns columns="3" columnGap="12px" px={contentPadding}>
                        {children}
                    </Columns>
                </ScrollWrapper>
            </BleedWrapper>
        );
    }
    if (contentLayoutMobile === 'rows' && isMobile) {
        return <Waterfall rowGap="40px">{children}</Waterfall>;
    }
    return (
        <Columns columns="3" columnGap="24px">
            {children}
        </Columns>
    );
};

const ThreeColumns: React.FunctionComponent<ThreeColumnsModuleProps> = ({ data, isHero, ...rest }) => {
    const {
        moduleSettings,
        data: { columns, heading, link, settings },
    } = data;
    const { contentLayout, reverseColumnsInMobile } = moduleSettings;
    const contentLayoutMobile = contentLayout?.mobile;

    const isMobile = useBreakpoint({ to: 'tablet.sm' });
    const notDesktop = useBreakpoint({ to: 'desktop.sm' });
    const device = isMobile ? 'mobile' : 'desktop';

    // TODO: Comment back when Heading is implemented in CMS
    // const moduleData = {};
    // // Heading
    // if (settings.includes('heading') && heading) {
    //     moduleData.heading = transformHeadingGroup(heading, device);
    // }

    // TODO: Comment back when Heading is implemented in CMS
    // // Link
    // if (settings.includes('link') && link) {
    //     const linkType = link[0].layout;
    //     if (linkType === 'buttons') {
    //         moduleData.buttons = link[0].buttons.map((button) => {
    //             const tButton = transformLinkButton(button.button);
    //             return {
    //                 url: tButton.link,
    //                 children: (
    //                     <>
    //                         {tButton.label}
    //                         <ArrowIcon />
    //                     </>
    //                 ),
    //                 theme: tButton.theme,
    //             };
    //         });
    //     } else if (linkType === 'content') {
    //         moduleData.contentLink = link[0].link;
    //     }
    // }

    // @TODO Implement background, spacing, ratio and visibility
    return (
        <FullWidth
            gridColumn="full-width"
            mt={isHero ? '' : 'var(--module-margin-top-default)'}
            mb="var(--module-margin-bottom-default)"
            p={['40px 12px', null, '24px 16px', null, '40px']}
            backgroundColor="var(--color-transparent)"
        >
            <VolatileContentLayoutWrapper
                isMobile={isMobile}
                notDesktop={notDesktop}
                contentLayoutMobile={contentLayout?.mobile}
            >
                <CmsBlocks
                    {...rest}
                    className="three_columns_item"
                    minWidth={['calc(90vw - 16px)', null, 'calc(45vw - 16px)', 'unset']}
                    data={reverseColumnsInMobile === 'yes' && isMobile ? Object.values(columns).reverse() : columns}
                    columnLayout="threeColumns"
                    contentLayoutMobile={contentLayoutMobile}
                    layout="threeColumns"
                />
            </VolatileContentLayoutWrapper>
        </FullWidth>
    );
};

export default ThreeColumns;
