import React from 'react';
import styled from '@grebban/style-system-react';
import asArray from '@grebban/array-utils/asArray';
import PropTypes from 'prop-types';

const StyledWrapper = styled('div')`
    display: grid;
`;

const Columns = ({ children, columns = '1', rowGap = '0px', columnGap = '0px', ...rest }) => (
    <StyledWrapper
        gridTemplateColumns={asArray(columns).map((column) => `repeat(${column}, 1fr)`)}
        columnGap={columnGap}
        rowGap={rowGap}
        {...rest}
    >
        {children}
    </StyledWrapper>
);

Columns.propTypes = {
    children: PropTypes.node,
    columnGap: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    columns: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    rowGap: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default Columns;
